const BuildingLogo = () => {
  return (
    <svg
      height="40"
      width="40"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      //   xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 176 176"
      //   xml:space="preserve"
    >
      <path
        className="dark:fill-white fill-[#24292f]"
        d="M155.706,176c-0.001,0-0.003,0-0.005,0l-65.411-0.125H20.294c-1.381,0-2.5-1.119-2.5-2.5V60.904
	c0-1.381,1.119-2.5,2.5-2.5h34.911V2.5c0-1.381,1.119-2.5,2.5-2.5h98c1.381,0,2.5,1.119,2.5,2.5v171c0,0.664-0.264,1.3-0.734,1.77
	C157.003,175.737,156.368,176,155.706,176z M92.794,170.88l60.411,0.115V5h-93v53.404h30.089c1.381,0,2.5,1.119,2.5,2.5V170.88z
	 M22.794,170.875h65V63.404h-65V170.875z M80.076,154.634H60.297c-1.381,0-2.5-1.119-2.5-2.5v-11.575c0-1.381,1.119-2.5,2.5-2.5
	h19.779c1.381,0,2.5,1.119,2.5,2.5v11.575C82.576,153.515,81.457,154.634,80.076,154.634z M62.797,149.634h14.779v-6.575H62.797
	V149.634z M50.292,154.634H30.513c-1.381,0-2.5-1.119-2.5-2.5v-11.575c0-1.381,1.119-2.5,2.5-2.5h19.779c1.381,0,2.5,1.119,2.5,2.5
	v11.575C52.792,153.515,51.673,154.634,50.292,154.634z M33.013,149.634h14.779v-6.575H33.013V149.634z M138.714,153.35h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5v-10.392c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C141.214,152.231,140.095,153.35,138.714,153.35z M131.652,148.35h4.563v-5.392h-4.563V148.35z M111.135,153.35h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5v-10.392c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C113.635,152.231,112.516,153.35,111.135,153.35z M104.072,148.35h4.563v-5.392h-4.563V148.35z M80.076,132.017H60.297
	c-1.381,0-2.5-1.119-2.5-2.5v-11.575c0-1.381,1.119-2.5,2.5-2.5h19.779c1.381,0,2.5,1.119,2.5,2.5v11.575
	C82.576,130.897,81.457,132.017,80.076,132.017z M62.797,127.017h14.779v-6.575H62.797V127.017z M50.292,132.017H30.513
	c-1.381,0-2.5-1.119-2.5-2.5v-11.575c0-1.381,1.119-2.5,2.5-2.5h19.779c1.381,0,2.5,1.119,2.5,2.5v11.575
	C52.792,130.897,51.673,132.017,50.292,132.017z M33.013,127.017h14.779v-6.575H33.013V127.017z M138.714,128.439h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5v-10.391c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.391
	C141.214,127.32,140.095,128.439,138.714,128.439z M131.652,123.439h4.563v-5.391h-4.563V123.439z M111.135,128.439h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5v-10.391c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.391
	C113.635,127.32,112.516,128.439,111.135,128.439z M104.072,123.439h4.563v-5.391h-4.563V123.439z M80.076,109.399H60.297
	c-1.381,0-2.5-1.119-2.5-2.5V95.324c0-1.381,1.119-2.5,2.5-2.5h19.779c1.381,0,2.5,1.119,2.5,2.5v11.575
	C82.576,108.28,81.457,109.399,80.076,109.399z M62.797,104.399h14.779v-6.575H62.797V104.399z M50.292,109.399H30.513
	c-1.381,0-2.5-1.119-2.5-2.5V95.324c0-1.381,1.119-2.5,2.5-2.5h19.779c1.381,0,2.5,1.119,2.5,2.5v11.575
	C52.792,108.28,51.673,109.399,50.292,109.399z M33.013,104.399h14.779v-6.575H33.013V104.399z M138.714,103.528h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V90.637c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C141.214,102.409,140.095,103.528,138.714,103.528z M131.652,98.528h4.563v-5.392h-4.563V98.528z M111.135,103.528h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V90.637c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C113.635,102.409,112.516,103.528,111.135,103.528z M104.072,98.528h4.563v-5.392h-4.563V98.528z M80.076,86.781H60.297
	c-1.381,0-2.5-1.119-2.5-2.5V72.706c0-1.381,1.119-2.5,2.5-2.5h19.779c1.381,0,2.5,1.119,2.5,2.5v11.576
	C82.576,85.662,81.457,86.781,80.076,86.781z M62.797,81.781h14.779v-6.576H62.797V81.781z M50.292,86.781H30.513
	c-1.381,0-2.5-1.119-2.5-2.5V72.706c0-1.381,1.119-2.5,2.5-2.5h19.779c1.381,0,2.5,1.119,2.5,2.5v11.576
	C52.792,85.662,51.673,86.781,50.292,86.781z M33.013,81.781h14.779v-6.576H33.013V81.781z M138.714,78.617h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V65.726c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.391
	C141.214,77.498,140.095,78.617,138.714,78.617z M131.652,73.617h4.563v-5.391h-4.563V73.617z M111.135,78.617h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V65.726c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.391
	C113.635,77.498,112.516,78.617,111.135,78.617z M104.072,73.617h4.563v-5.391h-4.563V73.617z M138.714,53.707h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V40.815c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C141.214,52.587,140.095,53.707,138.714,53.707z M131.652,48.707h4.563v-5.392h-4.563V48.707z M111.135,53.707h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V40.815c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C113.635,52.587,112.516,53.707,111.135,53.707z M104.072,48.707h4.563v-5.392h-4.563V48.707z M83.555,53.707h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V40.815c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C86.055,52.587,84.936,53.707,83.555,53.707z M76.493,48.707h4.563v-5.392h-4.563V48.707z M138.714,28.795h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V15.904c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C141.214,27.676,140.095,28.795,138.714,28.795z M131.652,23.795h4.563v-5.392h-4.563V23.795z M111.135,28.795h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V15.904c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C113.635,27.676,112.516,28.795,111.135,28.795z M104.072,23.795h4.563v-5.392h-4.563V23.795z M83.555,28.795h-9.563
	c-1.381,0-2.5-1.119-2.5-2.5V15.904c0-1.381,1.119-2.5,2.5-2.5h9.563c1.381,0,2.5,1.119,2.5,2.5v10.392
	C86.055,27.676,84.936,28.795,83.555,28.795z M76.493,23.795h4.563v-5.392h-4.563V23.795z"
      />
    </svg>
  );
};

export default BuildingLogo
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <div className="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100">
    <App />
  </div>
);
